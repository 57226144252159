import { GetApi, PostApi } from "./api"

export function getSetting() {
    return GetApi(`/setting`)
}


export function fetchCategories() {
    return GetApi(`/beat-categories?search`)
}

export function fetchBeats(current_select_category) {
    return GetApi(`/beat?search=${current_select_category}`)
}


export function playBeat(filename) {
    return GetApi(`/beat/${filename}`)
}


export function fetchVirtualTour() {
    return GetApi(`/virtual-tour`)
}

export function fetchVirtualTourCategories() {
    return GetApi(`/virtual-tour-categories`)
}