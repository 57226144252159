import React, { useEffect, useState } from "react";
import { MainHeader } from "../layouts/MainLayout/MainHeader";
import MainFooter from "../layouts/MainLayout/MainFooter";
import "../assets/css/VirtualTour.css";
import { NavLink, useNavigate } from "react-router-dom";
import MusicstudioVideo from "../assets/images/VirtualTourVideo.mp4"
import { GiDeliveryDrone } from "react-icons/gi";
import { MdOutlineCameraAlt } from "react-icons/md";

import About_1 from "../assets/images/virtual_tours_about2.png"
import About_3 from "../assets/images/virtual_tours_about3.png"
import { fetchCategories, fetchVirtualTour, fetchVirtualTourCategories, getSetting } from "../http/service";

const VirtualTour = () => {


  const [setting, setSetting] = useState(null);
  const [virtualTourCategories, setVirtualTourCategories] = useState([]);
  const [virtualTour, setVirtualTour] = useState([]);
  const navigate = useNavigate();

  async function getSettings() {
    let setting = await getSetting();
    if (setting.status) {
      setSetting(setting.setting);
    }
  }

  const getCategories = async() =>{
    const res = await fetchVirtualTourCategories();
    if(res.status){
      setVirtualTourCategories(res.categories);
    }
  }

  const getVirtualTour = async() =>{
    const res = await fetchVirtualTour();
    if(res.status){
      setVirtualTour(res.virtualTour);
    }
  }



  useEffect(() => {
    getSettings();
    getCategories();
    getVirtualTour();
  }, [])


  const onApplyCatFilter = (current_cat) => {
    getVirtualTour(current_cat);
  }


  const onViewVirtualTour = (v) =>{
    console.log(v);
    
    const virtual_link = btoa(v.virtual_tour);
    navigate(`/virtual-tour-view?url=${virtual_link}&title=${v.title}&desc=${v.description}`);
  }


  const about = [
    {
      title: "3D Scanning Digital Twins",
      content: "Digitize and view your space from multiple angles, build functionality on top of it, get accurate measurements, and more. With a single scan, you can automatically create 3D walk-throughs, 4K print quality photos, schematic floor plans, OBJ files, point clouds, videos, and other media.",
      icon: <img src={About_1} alt="About_1" />
    },
    {
      title: "Commercial Photography",
      content: "We are a team of professional photographers with a wealth of international experience offering our customers a total solution. From high end commercial shoots to property and architectural photography.",
      icon: <MdOutlineCameraAlt size={60} color="blue" />
    },
    {
      title: "Google 360 Street View",
      content: "As official Senior Google Maps Photographers, we have dedicated access to embed your Street View 360 imagery onto Maps to expose your location. Create the ultimate “see inside walk-through” of your business space.",
      icon: <img src={About_3} alt="About_3" />
    },
    {
      title: "Aerial Drone Photography",
      content: "Quality and safety is paramount when it comes to using our state of the art drone equipment. This enables us to capture stunning imagery, videos, survey footage and create 360 eye catching aerial photos. Learn more here",
      icon: <GiDeliveryDrone size={60} color="blue" />
    }
  ]

  return (
    <>
      <MainHeader setting={setting} />
      <section id="MusicStudio" className="mt100">
        <div className="virtual-studio">
          <video muted loop autoPlay>
            <source
              src={MusicstudioVideo}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
          <div className="music-studio-content mt-5">
            <h1 className="Heading-Virtual Heading-Virtual--mg">Virtual World Explore</h1>
            <span className="mr-2">360 VIRTUAL TOURS </span> <span class="dot"></span> <span> 3D PHOTOGRAPHY </span> <span class="dot"></span> <span> AERIAL</span>

            <div class="constrain mt-4">
              <input class="input-grey-rounded" type="text" placeholder="Search Beats, Keywords..." />
            </div>

          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="Heading-Virtual ">About Abhiraj Virtual Tour</h1>
            </div>
            <div className="col-sm-12 text-center">
              <p className="mt-3">Abhiraj Virtual Tour is a pioneering global company that excels in immersive asset digitization,
                360/3D virtual tours, digital twins, digital surveying, Scan to BIM, photography, and aerial drone operations. Our expertise spans across various industries, including AEC, Government, Luxury spaces, Retail chains, Shopping Centers, and beyond. </p>
            </div>

          </div>

          <div className="row">
            {
              about.map((item) => (
                <div className="col-sm-3 text-center mt-5">

                  <div> {item.icon} </div>
                  <div className="mt-3">
                    <h5>  {item.title}</h5>
                  </div>

                  <p>{item.content}</p>
                </div>
              ))
            }
          </div>

          <div className="row">
            <div className="col-12 text-center">
              <h1 className="Heading-Virtual ">Our Work</h1>
            </div>
            <div className="col-sm-12 text-center">
              <p className="mt-3 ">We let our work do the talking. Check out our latest projects below and experience the care and craftsmanship we put into every detail. </p>
            </div>
          </div>

          <div className="Tags">
            {(virtualTourCategories ?? []).map((cat, i) => (
              <span onClick={() => onApplyCatFilter(cat.label)}>{cat.label}</span>
            ))}
          </div>

          <div className="row">
            {virtualTour && virtualTour.length > 0 ? 

            (virtualTour ?? []).map((v,i)=>(
              <div className="col-3">
              <figure class="snip" onClick={()=>onViewVirtualTour(v)}>
                <img class="snip__image" src={`${process.env.REACT_APP_BASE_URL}/${v.thumbnail}`} alt="card" />
                <figcaption class="snip__figcaption">
                  <h3 class="snip__title">{v.title}</h3>
                  <p class="snip__text">
                    {v.description}
                  </p>
                </figcaption>
                <a class="snip__link" href="#"></a>
              </figure>
            </div>
            ))
           :
            <h2>No Data Found.</h2>
}
          </div>

        </div>
      </section>

      <MainFooter setting={setting} />
    </>
  );
};
export default VirtualTour;
