
/** @format */

import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
import { BasicDropdown } from "../../blocks/Modals/BasicDropdown";
import { FaPhoneAlt } from "react-icons/fa";
import ScrollToTop from "../../blocks/Modals/ScrollToTop";
import MainLogo from "../../assets/images/Abhiraj.png"

export const MainHeader = (props) => {
  const [collapse, setCollapse] = useState(false);
  // const dispatch = useDispatch();
  let [ishomepage, setIsHomepage] = useState(false);
  let location = useLocation();
  // const { user } = useSelector((state) => state.auth);

  console.log(props);
  

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHomepage(true);
    }
  }, [location.pathname]);
  
  return (
    <>
      <ScrollToTop />
      <header className="h_Shadow" style={{background:"#0c1b2b"}}>
        <div className="container-fluid" >
        <div className="row">
        <nav className="navbar navbar-expand-lg  navbar-light">
          <div className="col-lg-2 col-md-2 col-9 d-flex justify-content-md-center">
              <NavLink className="navbar-brand" to="/">
              <img
                  src={MainLogo}
                  alt="Abhiraj Music Studio"
                  className="logo"
                />
              </NavLink>
              <button
                className={`navbar-toggler ${
                  collapse ? "collapsed" : ""
                } border-0 shadow-none position-absolute m-3 end-0`}
                type="button"
                data-bs-toggle="collapse"
                aria-controls="homeNavbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setCollapse(!collapse)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className={`collapse navbar-collapse col-md-9 ${
                collapse ? "show" : ""
              }`}
              id="homeNavbar"
            >
              <ul className="Menu navbar-nav me-auto" style={{borderLeft:"0.1px solid #ffffff5e"}}>
                <li className="nav-item active mx-3">
                  <NavLink className="nav-a text-white" to="/">
                    {"Home"}
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-a text-white" to="/">
                    {"Services"}
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-a text-white" to="/">
                    {"About Us"}
                  </NavLink>
                </li>

    
                <li className="nav-item mx-3">
                  <NavLink className="nav-a text-white" to="/">
                {"Contact Us"}  
                  </NavLink>
                </li>
              </ul>
              <ul className="Menu navbar-nav align-items-lg-center">
                <li className="nav-item my-3">
                  <a
                    className="themeBlueColor text-decoration-none mx-4 text-white"
                    href={"tel:"+ props?.setting?.phone ? props?.setting?.phone : "+91 72529 26205"}
                  >
                    <FaPhoneAlt className="m-1" /> {props?.setting?.phone ? props?.setting?.phone : "+91 72529 26205"}
                  </a>
                </li>
                <li className="nav-item mx-1">
                  {/* {!user ? ( */}
                    <NavLink
                      className="btn-login"
                      to="/login"
                    >
                      Log in
                    </NavLink>
                  {/* ) : (
                    <BasicDropdown name={user?.user.fname} />
                  )} */}
                </li>
                <li className="nav-item mx-3">
                  {/* {!user ? ( */}
                    <NavLink
                      className="btn-signup"
                      to="/login"
                    >
                      Sign up
                    </NavLink>
                  {/* ) : (
                    <BasicDropdown name={user?.user.fname} />
                  )} */}
                </li>
              </ul>
            </div>

          </nav>
        </div>
        </div>
      </header>
    </>
  );
};



















// /** @format */

// import React, { useEffect, useState } from "react";
// import { useLocation, NavLink } from "react-router-dom";
// // import { useSelector, useDispatch } from "react-redux";
// import { BasicDropdown } from "../../blocks/Modals/BasicDropdown";
// import { FaPhoneAlt } from "react-icons/fa";
// import ScrollToTop from "../../blocks/Modals/ScrollToTop";
// import MainLogo from "../../assets/images/Abhiraj.png"
// export const MainHeader = () => {
//   const [collapse, setCollapse] = useState(false);
//   // const dispatch = useDispatch();
//   let [ishomepage, setIsHomepage] = useState(false);
//   let location = useLocation();
//   // const { user } = useSelector((state) => state.auth);

//   useEffect(() => {
//     if (location.pathname === "/") {
//       setIsHomepage(true);
//     }
//   }, [location.pathname]);

//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     // Toggle the state to open/close the menu
//     setIsOpen(!isOpen);
//   };
//   return (
//     <>
//       <header>
//       <div className="logo">
//         <img src={MainLogo} alt="Logo Image"/>
//       </div>
//       <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
//         <div className="line1"></div>
//         <div className="line2"></div>
//         <div className="line3"></div>
//       </div>
//       <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
//         <li><NavLink to="#">Home</NavLink></li>
//         <li><NavLink to="#">Products</NavLink></li>
//         <li><NavLink to="#">Services</NavLink></li>
//         <li><NavLink to="#">Contact Us</NavLink></li>
//         <li><button className="login-button">Login</button></li>
//         <li><button className="join-button">Join</button></li>
//       </ul>
//     </header>
//     </>
//   );
// };












