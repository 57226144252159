import React from "react";
import { MainHeader } from "../layouts/MainLayout/MainHeader";
import MainFooter from "../layouts/MainLayout/MainFooter";
import "../assets/css/Tour.css";

const Tour = () => {
  return (
    <>
      <MainHeader />
      <section id="Tour" className="mt100">
        <div className="w-100" style={{ height: "calc(85vh - 150px)" }}>
          <div className="container-fluid d-flex justify-content-center align-items-center h-100">
            <h1 className="text-center">This Part Is Coming Soon</h1>
          </div>
        </div>
        <div className="container pt-3">
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="d-flex justify-content-between flex-wrap-reverse">
                    <div className="d-flex mb-3 me-2">
                      <div className="me-2">
                        <a href="/grinev">
                          <img
                            className="rounded-circle me-2"
                            width="60"
                            height="60"
                            src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/60x60-c_User_1.png"
                          />
                        </a>
                      </div>
                      <div>
                        <h3 className="mb-2 fw-bold">
                          {"Washington State Capitol Hall"}
                        </h3>
                        <h5 className="fw-normal">
                          {"by "}
                          <a
                            href="/grinev"
                            className="text-dark text-decoration-none"
                          >
                            {"Roman Grinev"}
                          </a>
                          <a href="/user/grinev" className="ms-1">
                            <small>
                              <i className="fas fa-user"></i>
                            </small>
                          </a>
                          <span
                            ng-controller="userCardController"
                            ng-init='init({"id":1,"uname":"Roman Grinev","story":"Web Programmer. Panoramic photographer. Aerial photography, panoramic aerial photography.","lang":"ru","login":"grinev","email":"grinevri@gmail.com","address":"Park Town Square, 31600 Pete von Reichbauer Way South, Federal Way, WA 98003, USA","lat":47.2558312,"lng":-122.4425946,"website":"https:\/\/grinev.studio","utwitter":"grinevri","ufacebook":"grinevri","isFavorite":null,"avatar":"https:\/\/bucket.truevirtualtours.com\/public\/cache\/user_1\/User\/1\/avatar\/38086_v_7\/50x50-c_User_1.png","avatar_100":"https:\/\/bucket.truevirtualtours.com\/public\/cache\/user_1\/User\/1\/avatar\/38086_v_7\/100x100-c_User_1.png","background":"https:\/\/bucket.truevirtualtours.com\/public\/cache\/user_1\/User\/1\/background\/38089\/640x275_User_1.jpg","url":"\/user\/grinev"})'
                            className="ng-scope"
                          >
                            <button
                              className="above-stretched-link btn btn-xs ms-2 btn-light"
                              ng-className="{'btn-primary': local.isFavorite &amp;&amp; !local.isLoading.favorite,'btn-light': !local.isFavorite}"
                              ng-click="toggleFavorite($event.currentTarget,'User',1)"
                            >
                              <i
                                className="fa fa-spinner fa-spin ng-hide"
                                ng-show="local.isLoading.favorite"
                              ></i>
                              <span
                                className="icon icon-add-user"
                                ng-show="!local.isFavorite &amp;&amp; !local.isLoading.favorite"
                              ></span>
                              <span
                                className="icon icon-user ng-hide"
                                ng-show="local.isFavorite &amp;&amp; !local.isLoading.favorite"
                              ></span>
                              <span ng-if="!local.isFavorite" className="ng-scope">
                                Follow
                              </span>
                            </button>
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="d-flex mb-3">
                      <div className="me-3">
                        <a
                          href=""
                          className="cursor-pointer d-inline-block h4"
                          ng-click="toggleFavorite($event.currentTarget, 'Tour', 20872)"
                          ng-className="{'text-danger active': local.isFavorite &amp;&amp; !local.isLoading.favorite}"
                          target="_top"
                        >
                          <i
                            className="fa fa-spinner fa-spin ng-hide"
                            ng-show="local.isLoading.favorite"
                          ></i>
                          <i
                            className="fas fa-heart"
                            ng-show="!local.isLoading.favorite"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2"></div>

                  <div className="mb-2 d-flex">
                    <div className="me-3">
                      <i className="fas fa-map-marker-alt"></i>
                      501 13th Ave SW, Olympia, WA 98501, USA{" "}
                    </div>
                    <div className="me-3">
                      <strong className="font-weight-bold">Publish date</strong>:
                      05/11 2024{" "}
                    </div>
                  </div>

                  <div className="mb-3 d-flex">
                    <div className="text-center me-5">
                      Views count <br />
                      <strong className="h2">262</strong>
                    </div>
                    <div className="text-center me-5">
                      Number of panoramas <br />
                      <strong className="h2">3</strong>
                    </div>
                  </div>

                  <div>
                    <a
                      href="/tours?tagged=olympia"
                      target="_self"
                      className="btn btn-xs btn-pill
									btn-secondary
							 mb-2	"
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      Olympia{" "}
                    </a>

                    <a
                      href="/tours?tagged=thurston-county"
                      target="_self"
                      className="btn btn-xs btn-pill
									btn-secondary
							 mb-2	"
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      Thurston county{" "}
                    </a>

                    <a
                      href="/tours?tagged=washington"
                      target="_self"
                      className="btn btn-xs btn-pill
									btn-secondary
							 mb-2	"
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      Washington{" "}
                    </a>

                    <a
                      href="/tours?tagged=unitedstates"
                      target="_self"
                      className="btn btn-xs btn-pill
									btn-secondary
							 mb-2	"
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      United States{" "}
                    </a>

                    <a
                      href="/tours?tagged=airpanorama"
                      target="_self"
                      className="btn btn-xs btn-pill
									btn-primary
							 mb-2	"
                    >
                      <i className="fas fa-rocket"></i>
                      Airpanorama{" "}
                    </a>

                    <a
                      href="/tours?tagged=best-works"
                      target="_self"
                      className="btn btn-xs btn-pill
									btn-info text-white
							 mb-2	"
                    >
                      <i className="fas fa-tag"></i>
                      The best{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4"></div>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
};
export default Tour;
