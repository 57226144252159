import { useRoutes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import VirtualTour from "./pages/VirtualTour";
import Tours from "./pages/Tours";
import Tour from "./pages/Tour";
import MusicStudio from "./pages/MusicStudio";
import Authentication from "./pages/Authentication";
import VirtualTourView from "./pages/VirtualTourView";

const Routes = () => {
  const element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/virtual-tour/", element: <VirtualTour/> },
    { path: "/tours", element: <Tours /> },
    { path: "/tour/:name", element: <Tour /> },
    { path: "/music-studio/", element: <MusicStudio /> },
    { path: "/login/", element: <Authentication /> },
    { path: "/virtual-tour-view", element: <VirtualTourView /> },
  ]);

  return element;
};

export default Routes;
