import React, { useRef, useState, useEffect } from 'react';
import { FaSearchPlus, FaSearchMinus, FaArrowsAlt, FaExpand, FaCompress, FaSun, FaMoon, FaUndo } from 'react-icons/fa';
import { MainHeader } from '../layouts/MainLayout/MainHeader';
import MainFooter from '../layouts/MainLayout/MainFooter';
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import { useLocation } from 'react-router-dom';


function VirtualTourView() {
    const iframeRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [brightness, setBrightness] = useState(100);
    const [contrast, setContrast] = useState(100);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const url = atob(queryParams.get('url'));
    const title = queryParams.get('title');
    const desc = queryParams.get('desc');
    

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const zoomIn = () => {
        setScale(prevScale => Math.min(prevScale + 0.1, 2)); // Limit the max zoom to 2x
    };

    const zoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.1, 1)); // Limit the min zoom to 1x
    };

    const resetView = () => {
        setScale(1);
    };

    const toggleFullscreen = () => {
        if (iframeRef.current.requestFullscreen) {
            iframeRef.current.requestFullscreen();
        } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
            iframeRef.current.mozRequestFullScreen();
        } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari, Opera
            iframeRef.current.webkitRequestFullscreen();
        } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
            iframeRef.current.msRequestFullscreen();
        }
    };

    const increaseBrightness = () => {
        setBrightness(prevBrightness => Math.min(prevBrightness + 10, 200)); // Limit max brightness to 200%
    };

    const decreaseBrightness = () => {
        setBrightness(prevBrightness => Math.max(prevBrightness - 10, 0)); // Limit min brightness to 0%
    };

    const increaseContrast = () => {
        setContrast(prevContrast => Math.min(prevContrast + 10, 200)); // Limit max contrast to 200%
    };

    const decreaseContrast = () => {
        setContrast(prevContrast => Math.max(prevContrast - 10, 0)); // Limit min contrast to 0%
    };

    const resetAllFilters = () => {
        setScale(1);
        setBrightness(100);
        setContrast(100);
    };

    const myDivRef = useRef(null);

    const handleScroll = () => {
      if (myDivRef.current) {
        myDivRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    return (
        <>
            <MainHeader />
            <div className="virtual-studio">
                <div className="music-studio-content mt-5">
                    <h1 className="Heading-Virtual-View-Heading">{title}</h1>
                    <hr/>
                    <p className='p-3'>{desc}</p>
                    <hr/>
                    <div>
                        <button className='browse-project-btn mt-4' onClick={()=>handleScroll()}>BROWSE PROJECT</button>
                    </div>
                    <div className='mt-3' style={{cursor:"pointer"}} >
                        <MdKeyboardDoubleArrowDown size={35}  onClick={()=>handleScroll()}/>
                    </div>
                </div>
            </div>
            <div ref={myDivRef} style={{ marginTop: "190px", position: 'relative', width: '100%', height: '100vh', overflow: 'hidden' }}>
                <iframe
                    ref={iframeRef}
                    src={url}
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        transform: `scale(${scale})`,
                        transformOrigin: 'center',
                        transition: 'transform 0.3s ease',
                        filter: `brightness(${brightness}%) contrast(${contrast}%)`,
                    }}
                />
                <div style={{ position: 'absolute', bottom: 10, left: '50%', transform: 'translateX(-50%)', display: 'flex', gap: '10px', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>

                        <button
                            onClick={zoomOut}
                            style={{
                                fontSize: '24px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                border: 'none',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                            }}
                        >
                            <FaSearchMinus />
                        </button>

                        <button
                            onClick={zoomIn}
                            style={{
                                fontSize: '24px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                border: 'none',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                            }}
                        >
                            <FaSearchPlus />
                        </button>
                    
                        <button
                            onClick={toggleFullscreen}
                            style={{
                                fontSize: '24px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                border: 'none',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                            }}
                        >
                            {isFullscreen ? <FaCompress /> : <FaExpand />}
                        </button>

                        <button
                            onClick={decreaseBrightness}
                            style={{
                                fontSize: '24px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                border: 'none',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                            }}
                        >
                            <FaMoon />
                        </button>
                        <button
                            onClick={increaseBrightness}
                            style={{
                                fontSize: '24px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                border: 'none',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                            }}
                        >
                            <FaSun />
                        </button>
             

                  
                        <button
                            onClick={resetAllFilters}
                            style={{
                                fontSize: '24px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                border: 'none',
                                color: '#fff',
                                padding: '10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                            }}
                        >
                            <FaUndo />
                        </button>
                    </div>
                    <div style={{ marginTop: '10px', fontSize: '16px', color: '#fff' }}>
                        Zoom Level: {Math.round(scale * 100)}%
                    </div>
                    {isFullscreen && (
                        <div style={{ position: 'absolute', top: 10, left: '50%', transform: 'translateX(-50%)', background: 'rgba(0, 0, 0, 0.7)', color: '#fff', padding: '5px 10px', borderRadius: '5px' }}>
                            Fullscreen Mode
                        </div>
                    )}
                </div>
            </div>
            <MainFooter />
        </>
    );
}

export default VirtualTourView;
