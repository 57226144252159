import React, { useRef, useState } from "react";
import "../assets/css/Authentication.css";
import LoginImg1 from "../assets/images/login-img1.jpg"
import LoginImg2 from "../assets/images/login-img2.jpg"

const Authentication = () => {
  const containerRef = useRef(null);

  const [signInData, setSignInData] = useState({
    username: "",
    password: "",
  });

  const [signUpData, setSignUpData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const toggleForm = () => {
    if (containerRef.current) {
      containerRef.current.classList.toggle("active");
    }
  };

  const validateSignIn = () => {
    const newErrors = {};
    if (!signInData.username) {
      newErrors.username = "Username is required";
    }
    if (!signInData.password) {
      newErrors.password = "Password is required";
    }
    return newErrors;
  };

  const validateSignUp = () => {
    const newErrors = {};
    if (!signUpData.username) {
      newErrors.username = "Username is required";
    }
    if (!signUpData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(signUpData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!signUpData.password) {
      newErrors.password = "Password is required";
    } else if (signUpData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }
    if (signUpData.password !== signUpData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    return newErrors;
  };

  const handleSignInSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateSignIn();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Add your sign-in logic here
      console.log("Sign In form submitted", signInData);
    }
  };

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateSignUp();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      // Add your sign-up logic here
      console.log("Sign Up form submitted", signUpData);
    }
  };

  const handleInputChange = (e, form) => {
    const { name, value } = e.target;
    if (form === "signIn") {
      setSignInData({ ...signInData, [name]: value });
    } else if (form === "signUp") {
      setSignUpData({ ...signUpData, [name]: value });
    }
  };

  return (
    <>
    <section className="auth-section">
      <div className="container" ref={containerRef}>
        <div className="user signinBx">
          <div className="imgBx">
            <img
              src={LoginImg2}
              alt=""
            />
          </div>
          <div className="formBx">
            <form onSubmit={handleSignInSubmit}>
              <h2>Sign In</h2>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={signInData.username}
                onChange={(e) => handleInputChange(e, "signIn")}
              />
              {errors.username && <p className="error text-danger">{errors.username}</p>}
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={signInData.password}
                onChange={(e) => handleInputChange(e, "signIn")}
              />
              {errors.password && <p className="error text-danger">{errors.password}</p>}
              <input type="submit" value="Login" />
              <p className="signup">
                Don't have an account?
                <a href="#" onClick={toggleForm}> Sign Up.</a>
              </p>
            </form>
          </div>
        </div>
        <div className="user signupBx">
          <div className="formBx">
            <form onSubmit={handleSignUpSubmit}>
              <h2>Create an account</h2>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={signUpData.username}
                onChange={(e) => handleInputChange(e, "signUp")}
              />
              {errors.username && <p className="error text-danger">{errors.username}</p>}
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={signUpData.email}
                onChange={(e) => handleInputChange(e, "signUp")}
              />
              {errors.email && <p className="error text-danger">{errors.email}</p>}
              <input
                type="password"
                name="password"
                placeholder="Create Password"
                value={signUpData.password}
                onChange={(e) => handleInputChange(e, "signUp")}
              />
              {errors.password && <p className="error text-danger">{errors.password}</p>}
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={signUpData.confirmPassword}
                onChange={(e) => handleInputChange(e, "signUp")}
              />
              {errors.confirmPassword && (
                <p className="error text-danger">{errors.confirmPassword}</p>
              )}
              <input type="submit" value="Sign Up" />
              <p className="signup">
                Already have an account?
                <a href="#" onClick={toggleForm}> Sign in.</a>
              </p>
            </form>
          </div>
          <div className="imgBx">
            <img
              src={LoginImg1}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Authentication;
