import React, { useEffect, useState } from "react";
import { MainHeader } from "../layouts/MainLayout/MainHeader";
import MainFooter from "../layouts/MainLayout/MainFooter";
import { NavLink } from "react-router-dom";
import "../assets/css/Home.css";
import MusicStudio from "../assets/images/MusicStudio.jpeg";
import musicGirl from "../assets/images/musicGirl.jpg";
import HomeVideo from "../assets/images/3910618-hd_1920_1080_25fps.mp4"
import {getSetting} from "../http/service"
const Home = () => {


  const [setting,setSetting] = useState(null);

 async function getSettings(){
    let setting = await getSetting();
    if(setting.status){
      setSetting(setting.setting);
    }
  }

  console.log(setting,"setting");

  useEffect(()=>{
    getSettings()
  },[])

  return (
    <>
      <MainHeader setting={setting}/>
      <section id="Home" className=" mt100">
      <div className="home">
          <video muted loop autoPlay>
            <source
              // src="https://assets.codepen.io/6093409/river.mp4"
              src={HomeVideo}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
          <div className="home-content">
            <h1  data-newvalue="HARD" data-oldvalue="CODE" className="Heading Heading--mg">Music studio, classes, <br/> virtual tours</h1>
            <button>EXPLORE THE EXPERIENCE</button>
          </div>
        </div>
       
        <div className="row align-items-center justify-content-center ">
          <div className="col-md-6 p-lg-5 py-4">
            <div className="cardBox w-100">
              <div className="card text-center">
                <img src={musicGirl} className="card-img" alt="..." />
                <div className="card-img-overlay row align-content-between">
                  <div className="col-12">
                    <h2 className="card-title mt-md-4">Abhiraj Music Studio</h2>
                    <p>{"Your Gateway to Musical Excellence"}</p>
                  </div>
                  <div className="col-12">
                    <NavLink className="stretched-link btn themeBtnRed px-4 border-0 mb-md-4" to="/music-studio">
                      {"Click Here To Visit Now"}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="col-md-6 p-lg-5 py-4">
            <div className="cardBox w-100">
              <div className="card text-center">
                <img src={MusicStudio} className="card-img" alt="..." />
                <div className="card-img-overlay row align-content-between">
                  <div className="col-12">
                    <h2 className="card-title mt-md-4">{"Abhiraj Virtual Tours"}</h2>
                    <p>{"Explore Our Virtual World"}</p>
                  </div>
                  <div className="col-12">
                    <NavLink className="stretched-link btn themeBtnRed px-4 border-0 mb-md-4" to="/virtual-tour">
                      {"Click Here To Visit Now"}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MainFooter setting={setting}/>
    </>
  );
};

export default Home;
