import logo from './logo.svg';
import './App.css';
import "./assets/css/index.css";
import Routes from './routes';
import "./assets/css/Responsive.css"

function App() {
  return (
    <>
    <Routes/>
    </>
  );
}

export default App;
