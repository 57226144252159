import { configureStore } from "@reduxjs/toolkit";
// import authSlice from "./Auth/authSlice";
// import vehicleSlice from "./pages/Vehicle/VehicleSlice";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
// import formSlice from "./Sections/formSlice"

const reducers = combineReducers({
  // auth: authSlice.reducer,
  // vehicle: vehicleSlice.reducer,
  // formData: formSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
