import instance from "./http"

export async function PostApi(url,data){
    try {
        const result = await instance.post(url,data)
            return result.data
    } catch (error) {
        if(error){
            return error
        }
    }
}

export async function GetApi(url){
    try {
        const result = await instance.get(url)
        return result.data;
    } catch (error) {
        if(error){
            return error
        }
    }
}