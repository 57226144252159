import React, { useEffect, useRef, useState } from "react";
import { MainHeader } from "../layouts/MainLayout/MainHeader";
import MainFooter from "../layouts/MainLayout/MainFooter";
import { GoDotFill, GoArrowRight } from "react-icons/go";
import { PiGuitarLight } from "react-icons/pi";
import { Carousel, Modal } from "antd";
import "../assets/css/MusicStudio.css";
import chromatic from "../assets/images/chromatic.webp";
import MusicstudioVideo from "../assets/images/Music-studio-Video.mp4"
import QR from "../assets/images/PaymentQr.jpg"
import { fetchBeats, fetchCategories, getSetting } from "../http/service";

const resSettings = [
  {
    breakpoint: 768,
    settings: { slidesToShow: 2, slidesToScroll: 1 },
  },
];

const MusicStudio = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentOpenBeat, setCurrentOpenBeat] = useState({});

  const [categories, setCategories] = useState([]);
  const [beats, setBeats] = useState([]);

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const handleButtonClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };


  const getCategories = async () => {
    const res = await fetchCategories();
    if (res.status) {
      setCategories(res.categories);
    }
  }

  const getBeats = async (current_cat) => {
    const res = await fetchBeats(current_cat);
    if (res.status) {
      setBeats(res.beats);
    }
  }

  useEffect(() => {
    getCategories();
    getBeats("");
    getSettings()
  }, [])


  const onApplyCatFilter = (current_cat) => {
    console.log(current_cat, "ca")
    getBeats(current_cat);
  }


  const [setting, setSetting] = useState(null);

  async function getSettings() {
    let res = await getSetting();
    if (res.status) {
      setSetting(res.setting);
    }

  }

  const [activeCategory, setActiveCategory] = useState('All');

  const handleClick = (category) => {
    setActiveCategory(category);
    onApplyCatFilter(category);
  };

  const viewBeat = (beat) => {
    setCurrentOpenBeat(beat)
    setModal2Open(true);
  }


  return (
    <>
      <MainHeader setting={setting} />

      <section id="MusicStudio" className="mt100">
        <div className="music-studio">
          <video muted loop autoPlay>
            <source
              src={MusicstudioVideo}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
          <div className="music-studio-content">
            <h1 data-newvalue="HARD" data-oldvalue="CODE" className="Heading-Music Heading-Music--mg">Discover, Create <br /> Ultimate Music Platform</h1>

            <div className="constrain">
              <input className="input-grey-rounded" type="text" placeholder="Search Beats, Keywords..." />
            </div>

          </div>
        </div>

        <div className="container mt-5">
          <h2 className="fw-bolder">{"Abhiraj Studios Samples"}</h2>
          <h6>{"The best samples produced and curated by Abhiraj Studios"}</h6>
          <div className="Tags">


            <span
              onClick={() => handleClick('All')}
              style={{
                backgroundColor: activeCategory === 'All' ? 'lightblue' : '',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              All
            </span>

            {/* Category options */}
            {(categories ?? []).map((cat, i) => (
              <span
                key={i}
                onClick={() => handleClick(cat.label)}
                style={{
                  backgroundColor: activeCategory === cat.label ? 'lightblue' : '',
                  padding: '5px 10px',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
              >
                {cat.label}
              </span>
            ))}

          </div>

          <div className='container mt-4'>
            <div className='row d-flex'>
              {beats && beats.length > 0 ? (beats ?? []).map((b, i) => (
                <div className='col-md-3 mt-4 d-flex align-items-center justify-content-center'>
                  <div className="player ml-4">
                    <div className="imgbx">
                      <img src={`${process.env.REACT_APP_BASE_URL}/${b.thumbnail}`} alt="Beat" />
                    </div>
                    <div className='mt-2 text-center'>
                      <h4 style={{ color: "#0c1b2b" }}>{b.title}</h4>
                      <spna style={{ color: "#0c1b2b" }}>{b.description}</spna>
                    </div>
                    <div className="d-flex justify-content-center p-3">
                      <button className="btn btn-info text-white" onClick={() => viewBeat(b)}>View/Download Beat</button>
                    </div>

                  </div>
                </div>
              )) : <h4> Not beats Found</h4>}
            </div>
          </div>




        </div>
        <div id="chromatic" className="container">
          <div className="row align-items-center my-5">
            <div className="col-md-6" style={{ padding: "36px 48px" }}>
              <h2 className="fw-bolder">{"Chromatic"}</h2>
              <p>
                {
                  "Explore new creative territories with this loop-based, playable instrument plugin. Unlimited access for Samples and Studio subscribers."
                }
              </p>
            </div>
            <div className="col-md-6">
              <img src={chromatic} />
            </div>
          </div>
        </div>

      </section>
      <MainFooter setting={setting} />

      <Modal
        title="20px to Top"
        style={{
          top: 20,
        }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
      <br />
      <br />

      <Modal
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={false}
        style={{ backgroundColor: "red" }}
      >
        <div className='container mt-4'>
          {/* <span>{currentOpenBeat.title}</span>
            <div className='row d-flex'>
              { currentOpenBeat.beat && currentOpenBeat.beat.split(",").length > 0 ?  (currentOpenBeat.beat.split(",") ?? []).map((b, i) => (
                    <audio controls className="mt-4">
                      <source src={`${process.env.REACT_APP_BASE_URL}/beat/${b}`} />
                      <track kind="captions" src="path/to/captions.vtt" />
                      Your browser does not support the audio element.
                    </audio>
              )) :  <h4> Not beats Found</h4>
              
              }
            </div> */}
          <div className="d-flex justify-content-center">
            <img src={QR}></img>
          </div>
          <div className="d-flex justify-content-center">
            <a href="https://api.whatsapp.com/send?phone=7252926205">
              <button className="whatsapp-btn">
                Connect on WhatsApp
              </button>
            </a>
          </div>

        </div>
      </Modal>
    </>
  );
};
export default MusicStudio;