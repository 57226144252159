/** @format */

import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import MainLogo from "../../assets/images/Abhiraj.png";
const MainFooter = (props) => {
  let [ishomepage, setIsHomepage] = useState(false);
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHomepage(true);
    }
  }, [location.pathname]);
  return (
    <>
      <footer className="footerBG">
      <hr />
        <div className="container">
          <div className="row py-5 mx-2">
            <div className="col-md-3 col-12">
              <div className="row align-items-sm-center">
                <div className="col-12">
                  <NavLink className="navbar-brand" to="/">
                    <img
                      src={MainLogo}
                      alt="Abhiraj Music Studio"
                      className="logo"
                    />
                  </NavLink>
                  <p className="pt-3">
                    {
                      "We, Abhiraj Music Studio and Music Classes, located in Chandpur, Bijnor, Uttar Pradesh, offer a complete range of fire safety equipment including extinguishers, cabinets, stands and other fire protection products such as signs, blankets and much more"
                    }
                  </p>
                </div>
                <div className="col-12 social_icons d-flex">
                  <a
                    href={props?.setting?.facebook}
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1 border rounded-circle"
                  >
                    <FaFacebook />
                  </a>
                  <a
                   href={props?.setting?.instagram}
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1 border rounded-circle"
                  >
                    <FaInstagram />
                  </a>
                  <a
                     href={props?.setting?.twitter}
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1 border rounded-circle"
                  >
                    <FaTwitter />
                  </a>
                  <a
                     href={props?.setting?.linkedin}
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1  border rounded-circle"
                  >
                    <FaLinkedin />
                  </a>
                  <a
                     href={props?.setting?.youtube}
                    className="text-decoration-none text-white d-flex align-items-center p-2 m-1  border rounded-circle"
                  >
                    <FaYoutube />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">Quick Links</p>
              <ul className="list-unstyled">
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Home"}
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Services"}
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"About Us"}
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Why Us"}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">QUICK LINKS</p>
              <ul className="list-unstyled">
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Testimonials"}
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Contact Us"}
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Privacy Policy"}
                  </NavLink>
                </li>
                <li className="py-1">
                  <NavLink to="/" className="text-decoration-none text-white">
                    {"Gallery"}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mt-4 mt-mb-0">
              <p className="text-uppercase">Contact</p>
              <ul className="list-unstyled">
                <li className="py-1">
                {props?.setting?.Address}
                  {/* {
                    "Mohalla Chimman,Shri Ram Colony,Near Sargam Theatre,Gali No- 1, Chandpur, Bijnor, 246725, Uttar Pradesh"
                  } */}
                </li>
                <li className="py-1">
                  <a
                    href="mailto:abhirajmusicstudio@gmail.com"
                    className="text-decoration-none text-white"
                  >
                    {props?.setting?.email}
                  </a>
                </li>
                <li className="py-1">
                  <a
                    href="tel:1234567890"
                    className="text-decoration-none text-white"
                  >
                    {props?.setting?.phone}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyright text-center py-3 mx-2">
          {/* {"© Copyrights 2024. "} */}
          <NavLink className="text-white" to="/">
          {props?.setting?.footer_text}
            {/* {"Abhiraj Music Studio and Music Classes."} */}
          </NavLink>
          {/* {" All Right Reserved"} */}
        </div>
      </footer>
    </>
  );
};

export default MainFooter;
