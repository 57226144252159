import React from "react";
import { MainHeader } from "../layouts/MainLayout/MainHeader";
import MainFooter from "../layouts/MainLayout/MainFooter";
import { NavLink } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaGlobe, FaRegEye, FaHeart, FaSpinner, FaTags } from "react-icons/fa";
import { CiClock1 } from "react-icons/ci";
import "../assets/css/Tours.css";
const card_img_top =
  "https://bucket.truevirtualtours.com/public/cache/user_1/Panorama/171911/preview/190069/640x360_Panorama_171911.jpg";
const tourName ="washington-state-capitol-hall";

const Tours = () => {
  return (
    <>
      <MainHeader />
      <section id="Tours" className="container pt-3 mt100">
        <h3 className="mt-4 h1 text-center">{"Virtual Tours 360°"}</h3>
        <div className="mt-4">
          <div className="list-view">
            <div className="row justify-content-md-center">
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 col-xl-3 mb-4">
                <div className="card">
                  <img src={card_img_top} className="card-img-top" />
                  <div className="card-body text-center d-flex flex-column">
                    <a
                      href="/grinev"
                      title="Roman Grinev"
                      className="user-image above-stretched-link"
                    >
                      <img
                        src="https://bucket.truevirtualtours.com/public/cache/user_1/User/1/avatar/38086_v_7/50x50-c_User_1.png"
                        alt=""
                        className="card-profile-img"
                      />
                    </a>
                    <h5 className="card-title fw-bold">
                      {"Washington State Capitol Hall"}
                    </h5>

                    <p className="mb-4 d-none overflow-hidden d-sm-block text-muted">
                      <FaLocationDot />
                      {"501 13th Ave SW, Olympia, WA 98501, USA"}
                    </p>
                    <NavLink
                      to={`/tour/${tourName}`}
                      className="stretched-link"
                    />

                    <div className="mt-auto">
                      <a
                        href="/tour/washington-state-capitol-hall"
                        className="btn btn-sm text-center btn-light"
                      >
                        <FaGlobe />
                        {" 3"}
                      </a>
                      <button className="btn btn-light btn-sm ms-1">
                        <FaRegEye />
                        {" 250"}
                      </button>
                      <button className="btn btn-light btn-sm mx-1">
                        <CiClock1 />
                        {" 05/11 2024"}
                      </button>
                      <button className="btn btn-light btn-sm text-center above-stretched-link me-1">
                        <FaSpinner className="d-none" />
                        <FaHeart />
                      </button>

                      <button className="btn btn-light btn-sm above-stretched-link">
                        <FaTags />
                        {" 6"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <ul className="pagination float-right">
                <li className="page-item prev disabled">
                  <a className="page-link" target="_top">
                    «
                  </a>
                </li>
                <li className="page-item active">
                  <a
                    className="page-link"
                    href="/tours?page=1"
                    data-page="0"
                    target="_top"
                  >
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/tours?page=2"
                    data-page="1"
                    target="_top"
                  >
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/tours?page=3"
                    data-page="2"
                    target="_top"
                  >
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/tours?page=4"
                    data-page="3"
                    target="_top"
                  >
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/tours?page=5"
                    data-page="4"
                    target="_top"
                  >
                    5
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/tours?page=6"
                    data-page="5"
                    target="_top"
                  >
                    6
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/tours?page=7"
                    data-page="6"
                    target="_top"
                  >
                    7
                  </a>
                </li>
                <li className="page-item next">
                  <a
                    className="page-link"
                    href="/tours?page=2"
                    data-page="1"
                    target="_top"
                  >
                    »
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
};
export default Tours;
